/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { blockUser } from '../fn/chat/block-user';
import { BlockUser$Params } from '../fn/chat/block-user';
import { ChatDetailResponse } from '../models/chat-detail-response';
import { ChatListItemResponse } from '../models/chat-list-item-response';
import { ChatListResponse } from '../models/chat-list-response';
import { ChatUsersResponse } from '../models/chat-users-response';
import { createMessage } from '../fn/chat/create-message';
import { CreateMessage$Params } from '../fn/chat/create-message';
import { deleteChat } from '../fn/chat/delete-chat';
import { DeleteChat$Params } from '../fn/chat/delete-chat';
import { getChatDetail } from '../fn/chat/get-chat-detail';
import { GetChatDetail$Params } from '../fn/chat/get-chat-detail';
import { getChatListSingleChat } from '../fn/chat/get-chat-list-single-chat';
import { GetChatListSingleChat$Params } from '../fn/chat/get-chat-list-single-chat';
import { getChats } from '../fn/chat/get-chats';
import { GetChats$Params } from '../fn/chat/get-chats';
import { getMessage } from '../fn/chat/get-message';
import { GetMessage$Params } from '../fn/chat/get-message';
import { GetOrCreateChatResponse } from '../models/get-or-create-chat-response';
import { getOrCreateChatWithUser } from '../fn/chat/get-or-create-chat-with-user';
import { GetOrCreateChatWithUser$Params } from '../fn/chat/get-or-create-chat-with-user';
import { getUsers } from '../fn/chat/get-users';
import { GetUsers$Params } from '../fn/chat/get-users';
import { MessageDetailResponse } from '../models/message-detail-response';
import { unblockUser } from '../fn/chat/unblock-user';
import { UnblockUser$Params } from '../fn/chat/unblock-user';

@Injectable({ providedIn: 'root' })
export class ChatService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `blockUser()` */
  static readonly BlockUserPath = '/api/chat/block-user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockUser$Response(params: BlockUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return blockUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `blockUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockUser(params: BlockUser$Params, context?: HttpContext): Observable<void> {
    return this.blockUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createMessage()` */
  static readonly CreateMessagePath = '/api/chat/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMessage$Response(params: CreateMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageDetailResponse>> {
    return createMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMessage(params: CreateMessage$Params, context?: HttpContext): Observable<MessageDetailResponse> {
    return this.createMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageDetailResponse>): MessageDetailResponse => r.body)
    );
  }

  /** Path part for operation `getChatDetail()` */
  static readonly GetChatDetailPath = '/api/chat/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChatDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChatDetail$Response(params: GetChatDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<ChatDetailResponse>> {
    return getChatDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChatDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChatDetail(params: GetChatDetail$Params, context?: HttpContext): Observable<ChatDetailResponse> {
    return this.getChatDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChatDetailResponse>): ChatDetailResponse => r.body)
    );
  }

  /** Path part for operation `deleteChat()` */
  static readonly DeleteChatPath = '/api/chat/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChat()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChat$Response(params: DeleteChat$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteChat(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteChat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChat(params: DeleteChat$Params, context?: HttpContext): Observable<void> {
    return this.deleteChat$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getChatListSingleChat()` */
  static readonly GetChatListSingleChatPath = '/api/chat/list/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChatListSingleChat()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChatListSingleChat$Response(params: GetChatListSingleChat$Params, context?: HttpContext): Observable<StrictHttpResponse<ChatListItemResponse>> {
    return getChatListSingleChat(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChatListSingleChat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChatListSingleChat(params: GetChatListSingleChat$Params, context?: HttpContext): Observable<ChatListItemResponse> {
    return this.getChatListSingleChat$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChatListItemResponse>): ChatListItemResponse => r.body)
    );
  }

  /** Path part for operation `getChats()` */
  static readonly GetChatsPath = '/api/chat/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChats$Response(params?: GetChats$Params, context?: HttpContext): Observable<StrictHttpResponse<ChatListResponse>> {
    return getChats(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChats(params?: GetChats$Params, context?: HttpContext): Observable<ChatListResponse> {
    return this.getChats$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChatListResponse>): ChatListResponse => r.body)
    );
  }

  /** Path part for operation `getMessage()` */
  static readonly GetMessagePath = '/api/chat/message/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessage$Response(params: GetMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageDetailResponse>> {
    return getMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessage(params: GetMessage$Params, context?: HttpContext): Observable<MessageDetailResponse> {
    return this.getMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageDetailResponse>): MessageDetailResponse => r.body)
    );
  }

  /** Path part for operation `getOrCreateChatWithUser()` */
  static readonly GetOrCreateChatWithUserPath = '/api/chat/get-or-create-with-user/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrCreateChatWithUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrCreateChatWithUser$Response(params: GetOrCreateChatWithUser$Params, context?: HttpContext): Observable<StrictHttpResponse<GetOrCreateChatResponse>> {
    return getOrCreateChatWithUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrCreateChatWithUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrCreateChatWithUser(params: GetOrCreateChatWithUser$Params, context?: HttpContext): Observable<GetOrCreateChatResponse> {
    return this.getOrCreateChatWithUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetOrCreateChatResponse>): GetOrCreateChatResponse => r.body)
    );
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/api/chat/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params: GetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChatUsersResponse>>> {
    return getUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params: GetUsers$Params, context?: HttpContext): Observable<Array<ChatUsersResponse>> {
    return this.getUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChatUsersResponse>>): Array<ChatUsersResponse> => r.body)
    );
  }

  /** Path part for operation `unblockUser()` */
  static readonly UnblockUserPath = '/api/chat/unblock-user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unblockUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  unblockUser$Response(params: UnblockUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unblockUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unblockUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unblockUser(params: UnblockUser$Params, context?: HttpContext): Observable<void> {
    return this.unblockUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
