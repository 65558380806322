import { DestroyRef, inject, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, finalize, fromEvent, Observable, tap } from 'rxjs';
import { AuthService, ProfileResponse } from '@app/core/api';
import { Router } from '@angular/router';
import { RoutesEnum } from '@app/core/utils';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private readonly destroyRef = inject(DestroyRef);
    private readonly loggedInStorageKey = 'loggedIn'; // This key is used to log out the user from other tabs.
    private readonly identitySubject$ = new BehaviorSubject<ProfileResponse | null>(null);
    readonly identity$ = this.identitySubject$.asObservable();

    private readonly identityFetchingSubject$ = new BehaviorSubject<boolean>(false);
    readonly identityFetching$ = this.identityFetchingSubject$.asObservable();

    constructor(
        private authService: AuthService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.listenForStorageChanges();
    }

    /* As of now, we check the user is logged in by calling the profile API.
     * If it returns 401, then the user is not logged in or the token is not
     * valid anymore. Otherwise, we save the user information.
     *
     * If a 401 is returned, the HTTP interceptor will redirect the user to the login page.
     */
    fetchAndSetProfile(): Observable<ProfileResponse> {
        this.identityFetchingSubject$.next(true);

        return this.authService.getProfile({}).pipe(
            tap((profile) => {
                this.identitySubject$.next(profile);
                localStorage.setItem(this.loggedInStorageKey, 'true');
            }),
            catchError(() => {
                this.unsetProfile();
                return EMPTY;
            }),
            finalize(() => this.identityFetchingSubject$.next(false)),
        );
    }

    logout(): Observable<void> {
        return this.authService.logout().pipe(
            tap(() => {
                this.unsetProfile();
                localStorage.removeItem(this.loggedInStorageKey);
            }),
        );
    }

    private unsetProfile(): void {
        this.identitySubject$.next(null);
    }

    private listenForStorageChanges(): void {
        const defaultView = this.document.defaultView;
        if (!defaultView) {
            return;
        }
        fromEvent<StorageEvent>(defaultView, 'storage')
            .pipe(
                tap((event: StorageEvent) => this.logOutUserFromOtherTabs(event)),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    private logOutUserFromOtherTabs(event: StorageEvent): void {
        if (event.key === this.loggedInStorageKey && !event.newValue) {
            this.unsetProfile();
            this.router.navigate([RoutesEnum.ROOT]);
        }
    }
}
